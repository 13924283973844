<template>
  <content-wrapper title="Feedbacks" back>
    <template slot="actions">
      <el-button
        v-if="!detail.reply_message"
        size="small"
        type="success"
        @click="handleReply"
      >
        {{ labels.LBL_REPLY }}
      </el-button>
    </template>

    <div v-loading="loading">
      <div>
        <p>
          {{ labels.LBL_FROM }}: <b>{{ detail.name }}</b>
        </p>

        <p>{{ labels.LBL_DATE }}: {{ detail.created_at | readableDate }}</p>

        <p v-if="detail.phone">
          {{ labels.LBL_CONTACT_NO }}: {{ detail.phone }}
        </p>

        <p v-if="detail.address">
          {{ labels.LBL_ADDRESS }}: {{ detail.address }}
        </p>

        <p v-if="detail.interested_in">
          {{ labels.LBL_INTERESTED_IN }}:
          <span class="primary-text"> {{ detail.interested_in }}</span>
        </p>
      </div>

      <br />

      <div>
        {{ labels.LBL_MESSAGE }}:
        <div class="text-card">
          {{ detail.message }}
        </div>
      </div>

      <br />

      <div v-if="detail.reply_message">
        {{ labels.LBL_REPLY }}:
        <div v-html="detail.reply_message" class="text-card"></div>
      </div>
    </div>
  </content-wrapper>
</template>

<script>
  import { labels } from "@/common";
  import { mapState } from "vuex";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";

  export default {
    name: "FeedbackDetail",

    props: {
      feedbackId: {
        type: [String, Number],
        default: "",
      },
    },

    components: {
      ContentWrapper,
    },

    data() {
      return {
        labels,
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        detail: (state) => state.feedback.feedbackDetail,
      }),
    },

    watch: {
      feedbackId: {
        immediate: true,
        handler(nv) {
          this.loadMessageDetail(nv);
        },
      },
    },

    methods: {
      loadMessageDetail(id) {
        this.$store.dispatch("feedback/getMessageDetail", id);
      },

      handleReply() {
        this.$router.push({
          name: "Reply Feedback",
          params: { replyTo: this.feedbackId },
        });
      },
    },
  };
</script>

<style></style>
